import Item from "antd/es/list/Item";
import { useLocation } from "react-router-dom";
import {getDrawerList} from './configData';
import { BOTTOM_PANEL_HEIGHT_VAL, CHART_SCREEN_TYPE } from "./mp_chart_constants";
import { OF_TIME_FRAME, OF_TIME_FRAME_OTHER_D3_CHARTS, OF_TIME_FRAME_STRADDLE_CHARTS,TIME_FRAME_VALUES } from "./constant";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const keyMapping = {
  n: "name",
  i: "instr",
  s: "series",
  r: "resource",
  v: "vp",
  c: "cc",
  o: "of",
  k: "krl",
  ak: "akrl",
  as: "autosave",
  l: "line_chart",
  g: "tpo_group",
  m: "max_cmp_days",
  t: "tf",
};

const booleanFields = ["v", "c","o","k","ak","as","l"];
// Mapping for `t` values
const tMapping = {
  d: "daily",
  w: "weekly",
  ws: "weekly_s",
  m: "monthly",
  ms: "monthly_s",
  yd: "yearly_d",
};

// Function to map `t` values
const mapTValues = (tArray) => {
  return tArray.map((value) => tMapping[value] || value);
};


export const transformAndReverseMap = (data) => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const newKey = keyMapping[key] || key;

      // Transform `t` array values
      if (key === "t" && Array.isArray(value)) {
        value = mapTValues(value);
      }

      // Transform boolean fields
      if (booleanFields.includes(key)) {
        value = value === 1 ? true : value === 0 ? false : value;
      }

      return [newKey, value];
    })
  );
};

export const useQuery = (param) => {
    return new URLSearchParams(useLocation().search).get(param);
  };
  
export const getAccessToken = () => {
    return JSON.parse(localStorage.getItem("accessToken"));
  };
  
  export const setAccessToken = (token) => {
    localStorage.setItem("accessToken", JSON.stringify(token));
  };
  
  export const updateAccessToken = (token) => {
    let accessToken = JSON.parse(localStorage.getItem("accessToken"));
    accessToken = token;
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
  };

  // export const getIndexOfRoute=(route,search,auth)=>{
  //   // console.log("getIndexOfRoute: route and search",route,search,route+search,auth)
  //   let index=-1;
  //   const drawerListData=getDrawerList(auth)
  //   // console.log("getIndexOfRoute: route and search",route,search,route+search,drawerListData)
  //   if(route!=undefined || route!=null)
  //     {
  //       for(let i = 0; i < drawerListData.length; i++){ 
  //         if(drawerListData[i].route==route+search){
  //           console.log("getIndexOfRoute found: route and search",i)
  //           return i;
            
  //         }
  //       }
  //       for(let i = 0; i < drawerListData.length; i++){ 
  //         if(drawerListData[i].route==route){
  //           console.log("getIndexOfRoute found: route",i)
  //           return i;
  //         }
  //       }
  //   }
  //   console.log("getIndexOfRoute not found: ",index)
  //   return index;
  // }

  export const getIndexOfRoute=(route,search,auth)=>{
    // console.log("getIndexOfRoute: route and search",route,search,route+search,auth)
    let index=-1;
    const drawerListData=getDrawerList(auth)
    // console.log("getIndexOfRoute: route and search",route,search,route+search,drawerListData)
    if(route!=undefined || route!=null)
      {
        
        for(let i = 0; i < drawerListData.length; i++){ 
         if(drawerListData[i].items==undefined){
            if(drawerListData[i].route==route+search){
              console.log("getIndexOfRoute found: route and search",i)
              return {parentIndex:-1,childIndex:i}
              
            }
          }else{
            for(let j=0;j<drawerListData[i].items.length;j++)
            if(drawerListData[i].items[j].route==route+search){
              console.log("getIndexOfRoute found: route and search",i)
              return {parentIndex:i,childIndex:j}
              
            }
          }
        }

        for(let i = 0; i < drawerListData.length; i++){ 
          if(drawerListData[i].items==undefined){
             if(drawerListData[i].route==route){
               console.log("getIndexOfRoute found: route and search",i)
               return {parentIndex:-1,childIndex:i}
               
             }
           }else{
             for(let j=0;j<drawerListData[i].items.length;j++)
             if(drawerListData[i].items[j].route==route){
               console.log("getIndexOfRoute found: route and search",i)
               return {parentIndex:i,childIndex:j}
               
             }
           }
         }
        // for(let i = 0; i < drawerListData.length; i++){ 
        //   if(drawerListData[i].route==route){
        //     console.log("getIndexOfRoute found: route",i)
        //     return i;
        //   }
        // }
    }
    console.log("getIndexOfRoute not found: ",index)
    return {parentIndex:-1,childIndex:-1}
  }

 

  //TODO: handle no last name otherwise it will crash the app
  export const stringAvatar=(name)=> {
    
    return {
      sx: {
        bgcolor:'primaryTheme.shade01',
        color:'white',
        width: 36, height: 36, fontSize:"16px", cursor:"pointer"
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } 
  

  export const createDictList=(startTime, endTime, interval,gap)=> {
    // Define starting time, value and label
    let time = startTime;
    let value = 0;
    let label = "A";
    let lastLabel="A"
  
    // Define an empty list to store the dictionaries
    let dictList = [];
  
    // Loop through the time range from start to end
    while (time <= endTime) {
      // Create a new dictionary with the current value, label and time
      let dict = {
        value: value,
        label: label,
        time: time
      };
      // Add the dictionary to the list
      dictList.push(dict);
      // Increment the value
      value++;
      // Increment the label every interval minutes
      // if (value % (60 / interval) === 0) {
        if(value%gap==0){
        label = String.fromCharCode(lastLabel.charCodeAt(0) + 1);
        lastLabel=label;
        }
        else
        label=""
      // }
      // Increment the time by interval minutes
      let [hours, minutes] = time.split(":").map(Number);
      minutes += interval;
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }
      time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }
    // console.log("time interval list= ",dictList);
    return dictList;
  }

  export const createIncrementDictList=(startV, endV, gap)=> {
    // Define starting time, value and label
    let value = startV;
    let label = "0";
    let lastLabel="0"
  
    // Define an empty list to store the dictionaries
    let dictList = [];
  
    // Loop through the time range from start to end
    while (value <= endV) {
      
      if(value%gap==0){
        label = value;
        lastLabel=label;

        // Create a new dictionary with the current value, label and time
        let dict = {
          value: value,
          label: label,
        };
        // Add the dictionary to the list
        dictList.push(dict);
      }
      else
        label=""

      // Increment the value
      value++;
      
    }
    // console.log("time interval list= ",dictList);
    return dictList;
  }

  export const getRandomHexColor=()=>{
    // console.log("random getRandomHexColor","#"+ Math.floor(Math.random()*16777215).toString(16))
    return "#"+ Math.floor(Math.random()*16777215).toString(16);
  }
  export  const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    // console.log("random rgbToHex=",r,g,b,hex.length === 1 ? '0' + hex : hex)
    return hex.length === 1 ? '0' + hex : hex
  }).join('')

  export const breakDSTR=(dstr,maxProfile,tradeDates,symbol,startDate,instrumentData,jsonData)=>{
    // let dstr="_4.12._11"
    console.log("breakDSTR=",dstr,maxProfile,tradeDates,symbol)
    // dstr="5.5"
    // startDate="30-06-2023"
  let count=0;
  let total=0;
  let nDays=0;
  // let maxProfile=15;
  let result=dstr.split(".")
  result = result.filter((val) => {
      return val!=""
  });

  let tempDstr=""
  let finalData=[];
  let startDateIndex=tradeDates.findIndex(item => item == startDate);
  let totalDays=0;
  for(let i=result.length-1;i>=0;i--){
    if(result[i].length>0){
      if(result[i].startsWith("_")){
        let value=parseInt(result[i].slice(1))
        totalDays=totalDays+value;
      }else{
        let value=parseInt(result[i])
        totalDays=totalDays+value;
      }
    }
  }

  let length=tradeDates.length-startDateIndex-totalDays;  //1500-1-1306-35
  console.log("breakDSTR 1=",startDateIndex,length,tradeDates.length,totalDays,dstr);
  if(instrumentData && instrumentData.series && instrumentData.series!=""){
  //  instrumentData.series="16-10-2023"
    let seriesIndex=tradeDates.findIndex(item => item == instrumentData.series);
    console.log("breakDSTR series=",startDateIndex,length,tradeDates.length,totalDays,dstr,instrumentData.series,seriesIndex,startDate);
    if(seriesIndex!=-1 && seriesIndex!=tradeDates.length-1){
      length=seriesIndex+1-startDateIndex-totalDays;
      console.log("breakDSTR 2=",startDateIndex,seriesIndex,totalDays,length);
    }

  }
  if(length>0 && jsonData.tf=="daily"){
    result.push("_"+length)
  }
  let seriesIndex=tradeDates.findIndex(item => item == instrumentData.series);
  console.log("breakDSTR 3=",length,result,seriesIndex);

  for(let i=result.length-1;i>=0;i--){
      console.log("i=",i,result)
      if(result[i].length>0){
          if(result[i].startsWith("_")){
              let value=parseInt(result[i].slice(1))
              console.log("If 1 value=",value,total,count,nDays,result[i])
              if(count+value<maxProfile){
                if(tempDstr!="")
                tempDstr="_"+value+"."+tempDstr;
                else
                tempDstr="_"+value
                  count=count+value;
                  total=total+value;
                  nDays=nDays+value;
                  if(i==0){
                              finalData.push({
                                  dstr:tempDstr,
                                  nDays:nDays,
                                  index:total
                              })
                  }
                  console.log("If 2 value=",value,total,count,nDays,result[i])
              }else{
                if(tempDstr!="")
                tempDstr="_"+(maxProfile-count)+"."+tempDstr;
                else
                tempDstr="_"+(maxProfile-count)
                  let rem=value-(maxProfile-count);
                  console.log("Else 2 value=",value,total,count,nDays,result[i])
                  nDays=nDays+(maxProfile-count);
                  total=total+(maxProfile-count);
                  count=count+(maxProfile-count);
                  
                  console.log("Else 3 value=",value,total,count,nDays,result[i])
                  finalData.push({
                      dstr:tempDstr,
                      nDays:nDays,
                      index:total
                  })
                  nDays=0;
                  count=0;
                  tempDstr="";
                  while(rem>0){
                      if(rem<maxProfile){
                          tempDstr="_"+rem+tempDstr;
                          
                          total=total+rem;
                          nDays=rem;
                          count=rem;
                          if(i==0){
                              finalData.push({
                                  dstr:tempDstr,
                                  nDays:nDays,
                                  index:total
                              })
                              tempDstr=""
                          }else{
                              
                          }
                          rem=0;
                          
                          
                          
                          
                      }else{
                          tempDstr="";
                          tempDstr="_"+maxProfile+tempDstr;
                          rem=rem-maxProfile;
                          total=total+maxProfile;
                          nDays=maxProfile;
                          finalData.push({
                              dstr:tempDstr,
                              nDays:nDays,
                              index:total
                          })
                          nDays=0;
                          tempDstr="";
                          
                      }
                  }
              }
          }
          else{
              console.log("ELSE ",i)
              let value=parseInt(result[i])
              if(tempDstr!="")
                tempDstr=value+"."+tempDstr;
            else
                tempDstr=value;
                  count=count+1;
              nDays=nDays+value; 
              total=total+value;
              if(count>=maxProfile || i==0){
                  finalData.push({
                      dstr:tempDstr,
                      nDays:nDays,
                      index:total
                  })
                  tempDstr="";
                  count=0;
                  nDays=0;
              }    
              
          }
      }
      console.log("tempDstr=",tempDstr)
  }
  console.log("breakDSTR Output=",finalData)
  
  for(let i=finalData.length-1;i>=0;i--){
    finalData[i].startDate=tradeDates[startDateIndex];
      startDateIndex=startDateIndex+finalData[i].nDays;
    
      finalData[i].loaded=false;
  
  }
  console.log("breakDSTR Output=",finalData)
  return finalData;
  }

  export const findMaxInRangeGeneric=(data, leftIndex, rightIndex,fieldName)=> {
    if (leftIndex < 0 || rightIndex >= data.length || leftIndex > rightIndex) {
      throw new Error('Invalid index range');
    }
  
    const subset = data.slice(leftIndex, rightIndex + 1); // Extract subset of data
  
    // Initialize variables to hold max and min values
    let max = Number.MIN_VALUE;
    let min= Number.MAX_VALUE;
    
  
    // Iterate through the subset to find max and min values
    for (let i = 1; i < subset.length; i++) {
      if (subset[i][fieldName] > max) {
        max = subset[i][fieldName];
      }
      if (subset[i][fieldName] < min) {
        min = subset[i][fieldName];
      }
     
    }
  
    return { max, min };
  }
  
  export const getBottomPanelViewName=(plotType)=>{
    if(plotType=="fut")
      return "panel1_view_futures";
    else if(plotType=="spt")
      return "panel1_view_spectrum";
    else if(plotType=="opt")
      return "panel1_view_options";
    else if(plotType=="all_doix")
      return "panel1_view_ntm_oi_change";
    else if(plotType=="all_doi")
      return "panel1_view_oi_change";
    else return "unknown_bottom_view"
  }

  export const getBottomPanelHeightName=(plotType)=>{
    if(plotType=="fut")
      return "panel1_height_futures";
    else if(plotType=="spt")
      return "panel1_height_spectrum";
    else if(plotType=="opt")
      return "panel1_height_options";
    else if(plotType=="all_doix")
      return "panel1_height_ntm_oi_change";
    else if(plotType=="all_doi")
      return "panel1_height_oi_change";
    else return "unknown_bottom_height"
  }

  export const getBottomPanelDefaultHeight=(plotType)=>{
    if(plotType=="fut")
      return BOTTOM_PANEL_HEIGHT_VAL["FUTURE_CHART"]
    else if(plotType=="spt")
      return BOTTOM_PANEL_HEIGHT_VAL["SPECTRUM_CHARTS"]
    else if(plotType=="opt")
      return  BOTTOM_PANEL_HEIGHT_VAL["OPTIONS_CHART"]
    else if(plotType=="all_doix")
      return BOTTOM_PANEL_HEIGHT_VAL["NTM_OI_CHANGE_CHART"]
    else if(plotType=="all_doi")
      return  BOTTOM_PANEL_HEIGHT_VAL["OI_CHANGE_CHART"]
    else return 10;
  }

  export const getInfoPanelViewName=(plotType)=>{
    if(plotType=="candle")
      return "info_panel_view_candle";
    else if(plotType=="fut")
      return "info_panel_view_futures";
    else if(plotType=="spt")
      return "info_panel_view_spectrum";
    else if(plotType=="opt")
      return "info_panel_view_options";
    else if(plotType=="all_doix")
      return "info_panel_view_ntm_oi_change";
    else if(plotType=="all_doi")
      return "info_panel_view_oi_change";
    else return "unknown_info_panel_view"
  }

  export const getGammaSettingsName=(plotType)=>{
    
    if(plotType=="spt")
      return "gamma_visibility_spectrum";
    else if(plotType=="all_doix")
      return "gamma_visibility_ntm_oi_change";
    else return "unknown_gamma_settings"
  }

  export const getMvizSettingsName=(plotType)=>{
    
    if(plotType=="spt")
      return "mviz_visibility_spectrum";
    else if(plotType=="all_doix")
      return "mviz_visibility_ntm_oi_change";
    else return "unknown_gamma_settings"
  }

  export const isD3BasedLineChart=(plot,doix=false)=>{
    if((plot=="fut" || plot=="opt" || plot=="spt" || plot=="all_doi"|| plot=="all_doix")&& doix==false){
      return true
    }
    else 
      return false;
  }

  export const isCOTChangeAllowed=(plot,doix=false)=>{
    if((plot=="fut" || plot=="opt")&& doix==false){
      return true
    }
    else 
      return false;
  }

  export const getLineChartSettingsName=(plot,doix=false)=>{
    if((plot=="spt" )&& doix==false){
      return "Spectrum Chart"
    }
    else if((plot=="all_doi")&& doix==false){
      return "OI Change Chart"
    }
    else if((plot=="all_doix")&& doix==false){
      return "NTM OI Change Chart"
    }
    else 
      return "Line Chart";
  }

  export const isGammaAndMvizSettingsAvailable=(plot,doix=false)=>{
    if((plot=="spt" )&& doix==false){
      return true;
    }
   
    else if((plot=="all_doix")&& doix==false){
      return true;
    }
    else 
      return false;
  }

  export const getTimeFrameForLineChart=(plot,doix=false,of=true)=>{
    if((plot=="spt" || plot=="all_doi"|| plot=="all_doix")&& doix==false){
      return OF_TIME_FRAME_OTHER_D3_CHARTS;
    }
    else if(!of){
      return OF_TIME_FRAME_STRADDLE_CHARTS
    }
    else if((plot=="fut" || plot=="opt" )&& doix==false){
      return OF_TIME_FRAME
    }else{
      return [];
    }
  }

  //chart state for reload the browser tab in multichart container view
  export const getChartSavedState = (savedData,newObject, type = undefined,chartScreenType) => {
    let updatedData = [];
    let updatedSavedData = { ...savedData }; // Make a copy of savedData
  
    if (type !== undefined) {
      // console.log("Reload OFMain saveChartState if=", type, newObject, chartScreenType, type, savedData, savedData?.data?.length);
      
      let chartData = JSON.stringify(savedData); // Serialize savedData
      if (chartData) {
        chartData = JSON.parse(chartData); // Parse to make a copy
        updatedData = chartData.data || [];
      }
  
      // Logic to update data based on CHART_SCREEN_TYPE
      if (type === CHART_SCREEN_TYPE.FULL_CHART) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[1]) updatedData[1] = { key: 1 };
          if (updatedData[2]) updatedData[2] = { key: 2 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.TWO_COLUMNS) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[2]) updatedData[2] = { key: 2 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.TWO_ROWS) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[1]) updatedData[1] = { key: 1 };
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.GRID) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW) {
        if (updatedData && updatedData.length > 0) {
          if (updatedData[3]) updatedData[3] = { key: 3 };
          if (updatedData[4]) updatedData[4] = { key: 4 };
          if (updatedData[5]) updatedData[5] = { key: 5 };
        }
      }
  
      if (type === CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS) {
        if (updatedData && updatedData.length > 0) {
          // You can modify the data array here if needed
        }
      }
  
      console.log("SESSION DATA if=",savedData,updatedData,type)
      // Update the savedData copy
      updatedSavedData = {
        ...savedData,
        data: updatedData,
        type: type,
      };
    } else {
     
      // Check if the object with the same key already exists in the data array
      const index = savedData.data.findIndex((item) => item.key === newObject.key);
  
      if (index !== -1) {
        // If found, replace the object at the specific index
        // updatedData = savedData.data.map((item, i) => (i === index ? newObject : item));
        const existingObject = savedData.data[index];

  const mergedDrawingObjects = {
    ...existingObject.drawingObjects,  // Keep existing symbols
    ...newObject.drawingObjects,      // Add new symbols or overwrite existing ones
  };

  // Update the object while preserving other properties
  const updatedObject = {
    ...existingObject,
    ...newObject, 
    drawingObjects: mergedDrawingObjects,
  };

  // Replace in the array
  updatedData = savedData.data.map((item, i) => (i === index ? updatedObject : item));

      } else {
        // If not found, add the new object to the data array
        updatedData = [...savedData.data, newObject];
      }
  
      console.log("SESSION DATA else=",savedData,updatedData,type)
      // Update the savedData copy
      updatedSavedData = {
        ...savedData,
        data: updatedData,
        type: chartScreenType, // Update the type to the current chartScreenType
      };
    }
  
    console.log("Updated savedData:", updatedSavedData);
    
    // Return the updated savedData instead of setting it
    return updatedSavedData;
  };

  export const formatDatesToISO=(dates)=> {
    const formattedDates = dates.map((dateString) => {
      const [day, month, year] = dateString.split('-');
      const dateObject = new Date(`${year}-${month}-${day}`);
      const formattedDate = dateObject.toISOString().split('T')[0];
      return formattedDate;
    });
  
    return formattedDates;
  }

  // export const findClosestIndex = (list, target) => {
  //   // Parse the target
  //   const targetDateTime = dayjs(target, "DD-MM HH:mm");
  //   console.log("findClosestIndex",list,targetDateTime)
  
  //   let closestIndex = -1;
  //   let smallestDiff = Infinity;
  
  //   list.forEach((item, index) => {
  //     const itemDateTime = dayjs(item, "DD-MM HH:mm");
  //     const diff = Math.abs(targetDateTime.diff(itemDateTime));
  //     console.log("findClosestIndex outer=",diff,index,itemDateTime)
  //     if (diff < smallestDiff) {
  //        console.log("findClosestIndex inner=",diff,smallestDiff,index)
  //       smallestDiff = diff;
  //       closestIndex = index;
  //     }
  //   });
  
  //   return closestIndex;
  // };

  export const findClosestIndex = (list, target) => {
    console.log("findClosestIndex target=",target)
    // const targetDateTime = dayjs.tz(target, "DD-MM HH:mm", "Asia/Kolkata");
    const formattedTarget = dayjs(target).format("DD-MM HH:mm");

    // Parse the formatted target with a timezone
    const targetDateTime = dayjs.tz(formattedTarget, "DD-MM HH:mm", "Asia/Kolkata");
  
  
    if (!targetDateTime.isValid()) {
      console.error("Invalid target date:", target);
      return -1;
    }
  
    let closestIndex = -1;
    let smallestDiff = Infinity;
  
    list.forEach((item, index) => {
      const itemDateTime = dayjs.tz(item, "DD-MM HH:mm", "Asia/Kolkata");
  
      if (!itemDateTime.isValid()) {
        console.error("Invalid date in list:", item);
        return;
      }
  
      const diff = Math.abs(targetDateTime.diff(itemDateTime));
      console.log("findClosestIndex",index,diff,itemDateTime,targetDateTime,formattedTarget)
  
      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestIndex = index;
      }
    });
  
    return closestIndex;
  };

  function isTargetDateOutOfRange(list, targetDateString) {
    if (list.length === 0) return null; // Handle empty list

    // Parse the first and last date in the list
    const firstDate = dayjs(list[0], "DD-MM-YYYY HH:mm");
    const lastDate = dayjs(list[list.length - 1], "DD-MM-YYYY HH:mm");

    // Parse the target date (assuming targetDateString is in "DD-MM-YY" format)
    const targetDate = dayjs(targetDateString, "DD-MM-YYYY");

    if (!firstDate.isValid() || !lastDate.isValid() || !targetDate.isValid()) {
        console.error("Invalid date format");
        return null;
    }

    // Compare target date with first and last dates
    if (targetDate.isBefore(firstDate, "day")) {
        return -1;
    } else if (targetDate.isAfter(lastDate, "day")) {
        return-2;
    } else {
        return 0;
    }
}
//   export const findClosestIndexDrawingObjects = (list, targetDate, targetTime) => {
//     if(isTargetDateOutOfRange(list,targetDate)!=0){
//       return -1;
//     }
//     // console.log("Processedindex findClosestIndex targetDate=", targetDate, " targetTime=", targetTime);

//     // Combine targetDate and targetTime into a full datetime string
//     const targetDateTime = dayjs.tz(`${targetDate} ${targetTime}`, "DD-MM-YYYY HH:mm", "Asia/Kolkata");

//     if (!targetDateTime.isValid()) {
//         console.error("Invalid target date or time:", targetDate, targetTime);
//         return -1;
//     }


//     // console.log("Processedindex findClosestIndex 2 targetDate=", targetDate, " targetTime=", targetTime,targetDateTime);
//     let closestIndex = -1;
//     let smallestDiff = Infinity;

//     list.forEach((item, index) => {
//         // List items only have "DD-MM HH:mm", so we append the target year
//         const itemDateTime = dayjs.tz(`${item.split(" ")[0]} ${item.split(" ")[1]}`, "DD-MM-YYYY HH:mm", "Asia/Kolkata")
//         .year(targetDateTime.year()); // Ensure same year

//             // const itemDateTime = dayjs.tz(`${item.split(" ")[0]} ${item.split(" ")[1]}`, "DD-MM-YYYY HH:mm", "Asia/Kolkata");

//             // console.log("Processedindex findClosestIndex 3 targetDate=", targetDate, " targetTime=", targetTime,itemDateTime,index);
//         if (!itemDateTime.isValid()) {
//             console.error("Invalid date in list:", item);
//             return;
//         }

//         const diff = Math.abs(targetDateTime.diff(itemDateTime));
//         // console.log("findClosestIndex", index, diff, itemDateTime.format("DD-MM-YYYY HH:mm"), targetDateTime.format("DD-MM-YYYY HH:mm"));

//         if (diff < smallestDiff) {
//             smallestDiff = diff;
//             closestIndex = index;
//         }
//     });

//     return closestIndex;
// };

export const findClosestIndexDrawingObjects = (list, targetDate, targetTime) => {
  if (!list || list.length === 0) return -1;
      if(isTargetDateOutOfRange(list,targetDate)!=0){
      return -1;
    }

  // Convert target to a comparable format
  const targetDateTime = dayjs.tz(`${targetDate} ${targetTime}`, "DD-MM-YYYY HH:mm", "Asia/Kolkata");

  if (!targetDateTime.isValid()) {
      console.error("Invalid target date or time:", targetDate, targetTime);
      return -1;
  }

  let left = 0, right = list.length - 1;
  let closestIndex = -1;
  let smallestDiff = Infinity;

  while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      
      // Parse the date at mid index
      const itemDateTime = dayjs.tz(list[mid], "DD-MM-YYYY HH:mm", "Asia/Kolkata").year(targetDateTime.year());

      if (!itemDateTime.isValid()) {
          console.error("Invalid date in list:", list[mid]);
          return -1;
      }

      const diff = Math.abs(targetDateTime.diff(itemDateTime));

      // Update closest index if a smaller difference is found
      if (diff < smallestDiff) {
          smallestDiff = diff;
          closestIndex = mid;
      }

      // Move left or right based on comparison
      if (itemDateTime.isBefore(targetDateTime)) {
          left = mid + 1;
      } else {
          right = mid - 1;
      }
  }

  return closestIndex;
};

  export const getMPTradedatesBasedOnTF=(tf,UIData)=>{
    let dateList=UIData.tradeDates;
    if(tf==TIME_FRAME_VALUES.daily){
      dateList= UIData["tradeDates"];
    }
    else if(tf==TIME_FRAME_VALUES.weekly){
      dateList= UIData["tradeDates.weekly"]|| UIData.tradeDates;
    }
    else if(tf==TIME_FRAME_VALUES.weekly_series){
      dateList=  UIData["tradeDates.weekly_s"]|| UIData.tradeDates;
    }
    else if(tf==TIME_FRAME_VALUES.monthly){
      dateList=  UIData["tradeDates.monthly"]|| UIData.tradeDates;
    }
    else if(tf==TIME_FRAME_VALUES.monthly_series){
      dateList=  UIData["tradeDates.monthly_s"]|| UIData.tradeDates;
    }
    else if(tf==TIME_FRAME_VALUES.yearly){
      dateList=  UIData["tradeDates.yearly_d"]|| UIData.tradeDates;
    }
    return formatDatesToISO(dateList);
  
  }

  export const mergeLists=(list1, list2)=> {
     // Create a map from list1 using a composite key of date and time
  const map1 = new Map(list1.map(item => [`${item.date}-${item.time}`, item]));

  // Variable to count common objects between the two lists
  let commonCount = 0;

  // Merge list2 into map1, replacing and counting common objects
  list2.forEach(item => {
    const key = `${item.date}-${item.time}`;
    if (map1.has(key)) {
      commonCount++;
    }
    map1.set(key, item); // Replace or add the item
  });

  // Convert the merged map back to a list
  const mergedList = Array.from(map1.values());

  return {
    mergedList,
    commonCount
  };
  }
  

//replay bucket approx time calculation 
export const getApproxTime = (time, interval, bucketSize, index) => {
  // console.log("getApproxTime",time,interval,bucketSize,index)
  // Parse the input time into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Calculate the total seconds from the start of the day
  const totalSeconds = (hours * 60 + minutes) * 60;

  // Calculate the duration of each sub-bucket in seconds
  const subBucketDuration = (interval * 60) / bucketSize;

  // Determine the starting second of the target sub-bucket
  const targetSeconds = totalSeconds + index * subBucketDuration;

  // Convert the result back into hours, minutes, and seconds
  const approxHours = Math.floor(targetSeconds / 3600) % 24;
  const approxMinutes = Math.floor((targetSeconds % 3600) / 60);
  const approxSeconds = Math.floor(targetSeconds % 60);

  // // Format the result as HH:MM:SS
  // return `${approxHours.toString().padStart(2, "0")}:${approxMinutes
  //   .toString()
  //   .padStart(2, "0")}:${approxSeconds.toString().padStart(2, "0")}`;
   // Format the result as HH:MM
   return `${approxHours.toString().padStart(2, "0")}:${approxMinutes.toString().padStart(2, "0")}`;

};


const hexColor = "#0000FF"; // Blue color in hex
const transparency = 0.2; // Transparency value (0 for fully transparent, 1 for fully opaque)

// Function to convert hex to RGBA
export const hexToRGBA = (hex, alpha=0.2) => {
  // Remove the '#' if present
  const cleanHex = hex.replace('#', '');
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// // Use the function to get the RGBA value
// const rgbaColor = hexToRGBA(hexColor, transparency);

// // Set the fillStyle
// contextDummy.fillStyle = rgbaColor;
